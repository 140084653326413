.contactStrip {
    width:100%;
    background:rgb(63, 81, 181);
    color:white;
    font-family:Roboto Condensed;
    text-align: center;
    padding:5px 5px;
    font-size: .8rem;
}

.contactStrip :global(a) {
    color: white;
}

.phoneIcon {
    color: #FFF;
    font-size: 14px;
    padding:0 5px;
}

a:hover {
    color: #FFF
}

.emailIcon {
    color: #FFF;
    font-size: 14px;
    padding:0 5px 0 15px;
}

.logoContainer {
    width: 128px;
}

.logo {
    width:100%;
}

@media only screen and (min-width: 768px) {
    .contactStrip {
        text-align:right;
        font-size: 1.3rem;
        letter-spacing:2px;
        padding-right: 20px;
    }
}