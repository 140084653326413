nav .nav-link{
    font-family: Roboto Mono;
}
.nav-item{
    font-size: .95rem;
    margin:0px 4px;
}
nav{
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.navbar-light .navbar-toggler {
    margin: 0;
}