.wrapper {
    background-color: #000;
    padding: 16px;
    color: #eee;
}

.header {
    font-weight: bolder;
}

.links {
    margin-bottom: 8px;
}


.links a {
    padding: 0 8px;
    color: #eee;
}

.iconWrapper {
    margin: 0px 16px 0 8px;
}

.iconWrapper a {
    color: white
}

.iconWrapper i {
    margin-top: 8px;
    font-size: 24px;
}